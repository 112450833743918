import React, { Component } from 'react';
import { navigate, Link } from "gatsby"
import './manage.css'

//Components
import Button from './button';
import ManagedPumpContainer from './manage-pump-container'

//NPM
import styled from 'styled-components'
import Loader from 'react-loader-spinner'

//Parse
import { GetUserEvents, currentUserCheck, GetTicketStats } from '../services/parse-bridge'

const Spinner = styled.div`
    text-align: center;
    margin: 20px 0;
`

const WelcomeBanner = styled.h5`
    background-color: #ded1cf;
    padding: 12px 0 12px 31px;
    border-radius: 3px;
    box-shadow: 0 1px 5px #aba7a847;
    color: #000000a1;
    font-weight: 500;
    opacity: 0.9;
`
class Manage extends Component {
    
    constructor(props) {
        super(props) 
        this.state = {
            userId: "",
            fullName: "",
            loading: true,
            userPumps: [],
            managingEvent: false,
            managedEvent: null,
            hasTickets: false,
            ticketStats: undefined,
            statsLoaded: false
        }

        this.selectEvent = this.selectEvent.bind(this)
    }

    

    componentDidMount() {
        //Get the current user then get their pumps
        currentUserCheck().then(result => {
            result = result.data;
            this.setState({ userId: result.objectId , fullName: result.username}, () => {
                //Get them pumpsss
                GetUserEvents(this.state.userId).then(results => {
                    this.setState({ userPumps : results, loading: false })
                })
            })
        })
    }

    //An event was selected, change the state to a managing one
    selectEvent(event, tickets) {
        this.setState({ 
            managedEvent: event,
            managingEvent: true,
            hasTickets: tickets
        },()=>console.log(this.state))
    }

    getStats(eventId, pump) {
        GetTicketStats(eventId).then((results)=>{
            console.log("got stats func")
            let encodedString = encodeURIComponent(pump.name)
            navigate(`/dashboard/manage-page/?${eventId}&${encodedString}`, { state: { stats: results, pump: pump}})
        })
    }

    render() { 
        return (
            <div className="Manage">
                {
                    this.state.managingEvent ? <h1 onLoad={this.getStats(this.state.managedEvent.id)}>Managing {this.state.managedEvent.name}</h1> 
                    : null
                }
                
                <WelcomeBanner>Welcome back, {this.state.fullName}</WelcomeBanner>
                { !this.state.managingEvent ? <h1>Select an event to manage</h1> : null }
                <div className="ticketStats" style={{marginBottom: "15px", textAlign: "center"}}>
                    <Link to="/events/add"><Button title="Add an event" /></Link>
                </div>
                {this.state.loading && <Spinner><Loader type="Oval" color="#Fc444e" height="50" width="50" /></Spinner>}
                {
                    !this.state.managingEvent && this.state.userPumps.map(pump => (
                        <div key={pump.id} onClick={()=>{this.getStats(pump.id, pump)}}>
                            <ManagedPumpContainer
                                key={pump.id}
                                flyer={pump.flyer}
                                name={pump.name}
                                id={pump.id}
                                hasTickets={pump.hasTickets}
                            />
                        </div>
                    ))
                }
            </div>
        );
    }
}

 
export default Manage;