import React, { Component } from 'react'
import styled from 'styled-components'

//Parse
import { currentUserCheck, GetUserTickets } from '../services/parse-bridge'
import TicketItem from './ticket-item'
import moment from 'moment'

class MyTickets extends Component {
  state = {
    tickets: null,
  }

  componentDidMount() {
    //Get the current user then get their pumps
    currentUserCheck().then(result => {
      result = result.data
      this.setState(
        { userId: result.objectId, fullName: result.fullName },
        () => {
          GetUserTickets(this.state.userId).then(response => {
            this.setState({ tickets: response.data }, () => this.sortTickets())
            console.log(response.data)
          })
        }
      )
    })
  }

  sortTickets = () => {
    const tickets = this.state.tickets
    const today = moment().startOf('day')

    const pastTickets = tickets.filter(tic => {
      return (
        moment(tic.eventDate)
          .toDate()
          .getTime() <
        moment()
          .toDate()
          .getTime()
      )
    })
    const currentTickets = tickets.filter(tic => {
      return (
        moment(tic.eventDate)
          .toDate()
          .getTime() >
        moment()
          .toDate()
          .getTime()
      )
    })

    console.log(pastTickets)
    console.log(currentTickets)
  }

  render() {
    const { fullName, tickets } = this.state
    return (
      <Container>
        <InnerContainer>
          <ProfileInfoContainer>
            <ProfilePicture />
            <Welcome>{fullName}</Welcome>
            <ProfileInfo>
              {tickets && tickets.length} tickets | 6 favourites
            </ProfileInfo>
          </ProfileInfoContainer>
          <div>
            <UpcomingTicketsContainer>
              <SectionTitle>Tickets</SectionTitle>
              {tickets &&
                tickets.map((ticket, index) => (
                  <TicketItem
                    key={index}
                    name={ticket.eventName}
                    id={ticket.objectId}
                    type={ticket.ticketType}
                    flyer={ticket.flyerUrl || ''}
                    purchaseDate={moment(ticket.createdAt).format(
                      'MMMM Do YYYY'
                    )}
                  />
                ))}
            </UpcomingTicketsContainer>
            <PastTicketsContainer>
              <SectionTitle>Past Tickets</SectionTitle>
            </PastTicketsContainer>
          </div>
        </InnerContainer>
      </Container>
    )
  }
}

export default MyTickets

const Container = styled.div`
  padding: 30px 0;
`
const InnerContainer = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  background-color: #fff;

  @media (max-width: 950px) {
    grid-template-columns: auto;
  }
`
const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const ProfilePicture = styled.img`
  background-color: #eaeaea;
  height: 170px;
  width: 170px;
  border-radius: 85px;
`
const UpcomingTicketsContainer = styled.div``
const PastTicketsContainer = styled.div``
const Welcome = styled.h1`
  font-size: 24px;
  margin: 0 0 5px 0;
`
const ProfileInfo = styled.p``
const SectionTitle = styled.h2`
  margin: 50px 0;
`
const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-height: 135px;
  box-shadow: -2px 6px 12px rgba(176, 176, 176, 0.25);
  padding: 20px 30px;
  border-left: 6px solid #fc444e;
  margin: 0 0 15px 0;
`
const TicketInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
`
const ImageContainer = styled.div`
  height: 100px;
  width: 100px;

  @media (max-width: 950px) {
    height: 70px;
    width: 70px;
  }
`
const Flyer = styled.img`
  background-color: #eaeaea;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  margin: 0;
  object-fit: cover;
  /* flex: 1;/ */
`
const Name = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: 500;
`
const Date = styled.p`
  margin: 0;
  font-size: 14px;
  color: #656565;
  font-weight: 300;
`
const Order = styled.p`
  margin: 0;
  font-size: 12px;
  color: #656565;
  font-weight: 300;
`
