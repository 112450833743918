import React, { Component } from 'react'
import './login.css'
import Layout from './layout'

//NPM
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import { Mixpanel } from './mixpanel'

//Parse functions
import { loginUser, signupUser } from '../services/parse-bridge'

const LoginButton = styled.button`
  background-color: #fc444e;
  color: white;
  font-family: Avenir-Heavy;
  margin: 20px 0;
  display: block;
  margin: 0 auto;
  border: none;
  width: 70%;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(252, 68, 78, 0.41);
  max-width: 600px;
  transition: 2s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 0 5px 12px rgba(252, 68, 78, 0.42);
  }
`

const FormDisclaimer = styled.p`
  color: red;
  font-family: 'Avenir-Oblique';
  font-size: 14px;
  margin: 10px 0;
`

const Spinner = styled.div`
  text-align: center;
  margin: 15px 0;
`
const SignUpButton = styled.button`
  margin: 10px auto !important;
`
const GenderContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin: 20px auto;
  font-family: 'Avenir-Medium';
`
const TextInput = styled.input`
  max-width: 600px;
  width: 70%;
  padding: 10px;
  border: 1px solid rgba(240, 240, 240, 0.6);
  box-shadow: none;
  margin: 0 0 10px 0;
  border-radius: 5px;
`
const GenderInput = styled.input``

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      fullName: '',
      gender: '',
      loading: false,
      showError: false,
      showLogin: true,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(event) {
    this.setState({ loading: true })
    event.preventDefault()

    if (this.state.showLogin) {
      loginUser(this.state.email, this.state.password)
        .then(response => {
          this.setState({ loading: false })
          console.log('This is the login response')
          console.log(response)
          console.log('response data', response.data)
          localStorage.setItem('wdptoken', response.data.token)
          //Track tis login in mixpanel
          Mixpanel.identify(response.data.objectId)
          Mixpanel.track('Successful login')
          Mixpanel.people.set({
            $fullName: response.data.fullName,
            $email: response.data.email,
            $username: response.data.username,
          })
          console.log('mix panel updated')
          window.location.reload()
        })
        .catch(err => {
          console.log(err)
          Mixpanel.track('Unsuccessful login')
          this.setState({ showError: true, loading: false })
        })
    } else {
      signupUser(
        this.state.email,
        this.state.password,
        this.state.fullName,
        this.state.gender
      )
        .then(response => {
          this.setState({ loading: false })
          console.log(response)
          localStorage.setItem('wdptoken', response.data.token)
          //Track tis login in mixpanel
          Mixpanel.identify(response.data.objectId)
          Mixpanel.track('Successful login')
          Mixpanel.people.set({
            $fullName: response.data.fullName,
            $email: response.data.email,
            $username: response.data.username,
          })
          console.log('mix panel updated')
          window.location.reload()
        })
        .catch(err => {
          console.log(err)
          Mixpanel.track('Unsuccessful login')
          this.setState({ showError: true, loading: false })
        })
    }
  }

  updateInput = e => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        showError: false,
      },
      () => console.log(this.state)
    )
  }

  render() {
    return (
      <div className="Login">
        {this.state.showLogin ? (
          <>
            <img src={require('../images/logo_colour.png')} alt="WDP Logo" />
            <h1>Login/Register</h1>
            <p>Continue using your WhereDPump account info</p>
            <form onSubmit={this.handleSubmit}>
              <TextInput
                name="email"
                value={this.state.email}
                type="email"
                placeholder="Email Address"
                onChange={this.updateInput}
              />
              <TextInput
                name="password"
                value={this.state.password}
                type="password"
                placeholder="Password"
                onChange={this.updateInput}
              />
              <LoginButton type="submit">Login</LoginButton>
              <SignUpButton onClick={() => this.setState({ showLogin: false })}>
                Sign Up
              </SignUpButton>
              {this.state.showError && (
                <FormDisclaimer>
                  You've entered something wrong, please try again!
                </FormDisclaimer>
              )}
              {this.state.loading && (
                <Spinner>
                  <Loader type="Oval" color="#Fc444e" height="40" width="40" />
                </Spinner>
              )}
            </form>
          </>
        ) : (
          <>
            <img src={require('../images/logo_colour.png')} alt="WDP Logo" />
            <h1>Login/Register</h1>
            <p>Continue using your WhereDPump account info</p>
            <form onSubmit={this.handleSubmit}>
              <TextInput
                name="email"
                value={this.state.email}
                type="email"
                placeholder="Email Address"
                onChange={this.updateInput}
              />
              <TextInput
                name="fullName"
                value={this.state.fullName}
                type="text"
                placeholder="Full Name"
                onChange={this.updateInput}
              />

              <TextInput
                name="password"
                value={this.state.password}
                type="password"
                placeholder="Password"
                onChange={this.updateInput}
              />
              <GenderContainer>
                <div>
                  <GenderInput
                    onChange={this.updateInput}
                    type="radio"
                    name="gender"
                    value="male"
                  />{' '}
                  Male
                </div>
                <div>
                  <GenderInput
                    onChange={this.updateInput}
                    type="radio"
                    name="gender"
                    value="female"
                  />{' '}
                  Female
                </div>
              </GenderContainer>
              <LoginButton type="submit">Sign Up</LoginButton>
              {this.state.showError && (
                <FormDisclaimer>
                  You've entered something wrong, please try again!
                </FormDisclaimer>
              )}
              {this.state.loading && (
                <Spinner>
                  <Loader type="Oval" color="#Fc444e" height="40" width="40" />
                </Spinner>
              )}
            </form>
          </>
        )}
      </div>
    )
  }
}

export default Login
