import React, { Component } from 'react'
import styled from 'styled-components'

const TicketContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  min-height: 135px;
  box-shadow: -2px 6px 12px rgba(176, 176, 176, 0.25);
  padding: 20px 30px;
  border-left: 6px solid #fc444e;
  margin: 0 0 15px 0;

  @media (max-width: 600px) {
    padding: 10px 15px;
  }
`
const TicketInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
`
const ImageContainer = styled.div`
  height: 100px;
  width: 100px;

  @media (max-width: 950px) {
    min-height: 50px;
    height: 100%;
    min-width: 50px;
    overflow: hidden;
  }
`
const Flyer = styled.img`
  background-color: #eaeaea;
  height: 100%;
  width: 100%;
  border-radius: 50px;
  margin: 0;
  object-fit: cover;
  /* flex: 1;/ */
`
const Name = styled.h2`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
`
const Type = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
`
const Date = styled.p`
  margin: 0;
  font-size: 14px;
  color: #656565;
  font-weight: 300;
`
const Order = styled.p`
  margin: 0;
  font-size: 12px;
  color: #656565;
  font-weight: 300;
`

class TicketItem extends Component {
  state = {}
  render() {
    return (
      <TicketContainer>
        <TicketInfo>
          <Name>{this.props.name}</Name>
          <Type>{this.props.type}</Type>
          <Date>{this.props.date}</Date>
          <Order>
            Ticket ID {this.props.id}, purchased on {this.props.purchaseDate}
          </Order>
        </TicketInfo>
        <ImageContainer>
          <Flyer src={this.props.flyer} />
        </ImageContainer>
      </TicketContainer>
    )
  }
}

export default TicketItem
