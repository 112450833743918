import React, { Component } from 'react'
import Layout from '../components/layout'

import { Mixpanel } from '../components/mixpanel'

//Parse functions
import { currentUserCheck, loginUser } from '../services/parse-bridge'
import Login from '../components/login'
import Manage from '../components/manage'

//NPM
import styled from 'styled-components'
import Loader from 'react-loader-spinner'
import MyTickets from '../components/my-tickets'

class MyTicketsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loggedIn: false,
      loading: true,
    }
  }

  componentDidMount() {
    currentUserCheck()
      .then(result => {
        console.log('result of current user check in dash', result.data)
        this.setState({ loggedIn: result.data, loading: false })
      })
      .catch(err => {
        console.log('caught an error checking user: ' + err)
        this.setState({ loading: false })
      })
    Mixpanel.track('Land on My Tickets')
  }

  render() {
    if (this.state.loggedIn) {
      return (
        <Layout>
          <MyTickets />
        </Layout>
      )
    }

    return (
      <Layout>
        <div>
          {this.state.loading ? (
            <Spinner>
              <Loader type="Oval" color="#Fc444e" height="40" width="40" />
            </Spinner>
          ) : (
            <Login />
          )}
        </div>
      </Layout>
    )
  }
}

export default MyTicketsPage

const Container = styled.div``
const Spinner = styled.div`
  text-align: center;
  /* margin: 15px 0; */
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 80vh;
`
