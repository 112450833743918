import React, { Component } from 'react';
import './pump-container.css'

import chev from '../images/right-chevron.png'


class ManagedPumpContainer extends Component {
    state = {  }
    render() { 
        const link = `/events/${this.props.id}`
        return (
            <div className="PumpContainer">
            
                <div className="container">
                    <div className="eventFlyer">
                        <img src={this.props.flyer} />
                    </div>
                    <div className="detailsContainer">
                        <div className="name">
                            <p>{this.props.name}</p>
                        </div>
                    </div>
                    <div className="chevron">
                        <img src={chev} />
                    </div>
                </div>
            </div>
        );
    }
}
 
export default ManagedPumpContainer;